<template>
  <main ref="location" class="location__main">
    <slot></slot>

    <div class="location__to-top-wrapper">
      <button
        class="location__to-top"
        aria-label="Back to Locations selector"
        @click="scrollToCities()"
      >
        <img
          src="/wp-content/themes/160over90-2021/src/images/pick-a-city.png"
          alt="Pick a City"
        />
      </button>
    </div>
  </main>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default {
  methods: {
    scrollToLocation() {
      const scrollEnd = this.$refs.location.offsetTop - 80;
      gsap.to(window, {
        duration: 1.5,
        scrollTo: scrollEnd,
        ease: "sine",
      });

      this.$refs.location.focus();
    },

    scrollToCities() {
      const scrollEnd = document.getElementById("location-nav").offsetTop - 120;
      gsap.to(window, {
        duration: 1.5,
        scrollTo: scrollEnd,
        ease: "sine",
        onComplete: function () {
          document
            .getElementById("location-nav")
            .getElementsByTagName("a")[0]
            .focus();
        },
      });
    },

    toTopPin() {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".location__main",
          start: "top bottom",
          end: "bottom 65%",
          toggleActions: "play reverse play reverse",
        },
      });

      tl.to(".location__to-top", {
        display: "block",
        opacity: "1",
        duration: 0.25,
      });
    },
  },
  mounted() {
    this.scrollToLocation();
    this.toTopPin();
  },
};
</script>
